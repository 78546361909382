.top-header{
    display: flex;
    background-color: #141D38;
    height: 45px;
    justify-content: space-between;

}

.father-style{
    background-color: #141D38 ;
    @media (max-width: 768px) {
        width: 100%;
        background-color:red
      }
}


.middle-header{
   
    display: flex;  
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    
}
@media (max-width: 425px){
    .middle-header{
       font-size: 11px;
    }
}
@media (max-width: 768px) {
    .middle-header{
    flex-direction: column;
    }
    .middle-header-links{
        margin-bottom: 20px;
    }
  }

.top-header-icons{
    display: flex;
    flex-direction: row;
    align-items: center;

}

.spaceItems{
    padding: 0px 13px 0px;
}
.top-header-text{
    color:#FFFFFF;
    display: flex;
    font-size: 14px;
    align-items: center;
    flex-direction: row;
    
    
}