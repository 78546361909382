@import url('https://fonts.googleapis.com/css?family=Montserrat');

.main-footer{
  background-color: #201D3C;
}


.main-footer-style{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    color:white;
    /* padding: 7vw; */
    font: 'Montserrat';
    
  }
  
.footer-title-style{
    font-size: 16px;
  }
.footer-list-style{
    font-size: 15px;
    color: #8E88B9;
}
.footer-list-style>p{cursor: pointer;}

@media (max-width: 768px) {
  .main-footer-style{
    display: none;
  }
  .main-footer{
    display: flex ;
    justify-content: center;
    align-items: center;
  }
}