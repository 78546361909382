@import url("https://fonts.googleapis.com/css?family=Montserrat");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;1,200&display=swap");


.services {
    background-image: linear-gradient( to bottom right,#232238, #252150 );
  }
@media (max-width: 425px) {
    #cards-circuit {
     display: grid!important;
    }
  } 
  

.items-parent-style{
    border:1px solid #46416B;
    background-color:#262151;
    margin:1%;
    position: relative;
  }
  
  .items-parent-style>div{margin: 7%;}
  
  .items-color-unSelected {
    color: white;
    font: normal normal 600 14px/30px Montserrat;
  }
  .items-color-selected {
    color: #44baff;
    background-color: #201b3e;
    font: normal normal 600 14px/30px Montserrat;
  }
  .items-description {
    text-align: left;
    font: normal normal normal 15px/30px Montserrat;
    letter-spacing: -0.38px;
    color: #a5a1c3;
  }
  .items-title {
    text-align: left;
    font: normal normal bold 23px/36px Montserrat;
    color: #ffffff;
  }
  .section-title{
    color: #8E88B9;
    font: normal normal 600 14px/30px Montserrat;
    text-align: center;
    padding-top: 50px;
  }
  .section-subtitle{
    color: #FFFFFF;
    font:normal normal bold 37px/45px Montserrat;
    text-align: center;
  }
  .corner-style{
      margin: 0 !important;
      position: absolute;
      background: transparent linear-gradient(180deg, #23B3DD 0%, #42619C 100%) 0% 0% no-repeat padding-box;
      padding: 20px;
      right: 0px;
      border-radius: 0% 0% 0% 95%;
      color: #FFFFFF;
      font: normal normal 300 37px/45px Montserrat;
  }


