.company-style {
  background-color: #f8f8f8;
  justify-content: "space-around";
  display: "flex";
  padding: 70px;
}

.companies-section {
  justify-content: space-between;
  display: flex !important;
  background-color: #f8f8f8;
  padding: 70px;
}

@media (max-width: 768px) {
  .companies-section {
    overflow-x: auto;
  }
}
