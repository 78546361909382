@import url("https://fonts.googleapis.com/css?family=Montserrat");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;1,200&display=swap");

.home-main-image {

  height: auto;
  position: relative;
  width: 100%;
}
.home-main-image > img {
  width: 100%;
}
.text-style {
  position: absolute;
  color: white;
  top: 30%;
left: 15%;
font: Monserrat;
font-size: 25px;
  
}
@media (max-width: 1404px) {
  .text-style {
   top: 0px;
position: absolute;
color: white;
  }
}

.main-text-title {
margin-bottom: 5px;
}
@media (max-width: 600px) {
  .main-text-title {
    font-size: 1rem;
  }
}
@media (max-width: 768px) {
  .main-text-title {
    font-size: 2rem;
  }
}
@media (max-width: 425px) {
  .main-text-title {
    font-size: 20px;
  }
}

/* .above-subtitle {
  font-size: 22px;
} */
@media (max-width: 425px) {
  .above-subtitle {
    font-size: 17px;
    display: none;
  }
}
.subtitle {
  font-size: 16px;
  padding-bottom: 0px;
  
}@media (max-width: 768px) {
  .subtitle {
    display: none;
  }
}


.read-more-style {
  background-color: #44baff;
  border: none;
  color: white;
  padding: 8px 15px;
  cursor: pointer;
  
}@media (max-width: 480px) {
  .read-more-style {
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.div-services-father{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}
.read-more-services-style {

  border: none;
  color: white;
  padding: 15px 32px;
  background-color: #141d38;
  border-style: inset;
}@media (max-width: 480px) {
  .read-more-services-style {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.worker-container {
  display: "flex";
  justify-content: "space-evenly";
  flex-direction: "row";
}

.upper-worker-text {
  font: "Montserrat";
  font-weight: 700;
  font-size: 14px;
  color: #7142b1;
}


.middleBot-worker-text {
  font: "Montserrat";
  font-weight: normal;
  font-size: 15px;
  color: #6d6d6d;
  letter-spacing: -0.38px;
}

.bot-worker-text {
  font: italic normal bold 15px/30px Montserrat;
  font-weight: 800;
  font-size: 15px;
  letter-spacing: -0.38px;
  color: #1b1d21;
  margin-bottom: 40px;
}

.video-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.video-button > p {
  margin-left: 6%;
  color: #1b1d21;
  font-weight: bold;
  cursor: pointer;
}

.services {
  background-image: linear-gradient( to bottom right,#232238, #252150 );
}

