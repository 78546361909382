@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;1,200&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;1,200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.top-header{
    display: flex;
    background-color: #141D38;
    height: 45px;
    justify-content: space-between;

}

.father-style{
    background-color: #141D38 ;
    @media (max-width: 768px) {
        width: 100%;
        background-color:red
      }
}


.middle-header{
   
    display: flex;  
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    
}
@media (max-width: 425px){
    .middle-header{
       font-size: 11px;
    }
}
@media (max-width: 768px) {
    .middle-header{
    flex-direction: column;
    }
    .middle-header-links{
        margin-bottom: 20px;
    }
  }

.top-header-icons{
    display: flex;
    flex-direction: row;
    align-items: center;

}

.spaceItems{
    padding: 0px 13px 0px;
}
.top-header-text{
    color:#FFFFFF;
    display: flex;
    font-size: 14px;
    align-items: center;
    flex-direction: row;
    
    
}
.home-main-image {

  height: auto;
  position: relative;
  width: 100%;
}
.home-main-image > img {
  width: 100%;
}
.text-style {
  position: absolute;
  color: white;
  top: 30%;
left: 15%;
font: Monserrat;
font-size: 25px;
  
}
@media (max-width: 1404px) {
  .text-style {
   top: 0px;
position: absolute;
color: white;
  }
}

.main-text-title {
margin-bottom: 5px;
}
@media (max-width: 600px) {
  .main-text-title {
    font-size: 1rem;
  }
}
@media (max-width: 768px) {
  .main-text-title {
    font-size: 2rem;
  }
}
@media (max-width: 425px) {
  .main-text-title {
    font-size: 20px;
  }
}

/* .above-subtitle {
  font-size: 22px;
} */
@media (max-width: 425px) {
  .above-subtitle {
    font-size: 17px;
    display: none;
  }
}
.subtitle {
  font-size: 16px;
  padding-bottom: 0px;
  
}@media (max-width: 768px) {
  .subtitle {
    display: none;
  }
}


.read-more-style {
  background-color: #44baff;
  border: none;
  color: white;
  padding: 8px 15px;
  cursor: pointer;
  
}@media (max-width: 480px) {
  .read-more-style {
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.div-services-father{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}
.read-more-services-style {

  border: none;
  color: white;
  padding: 15px 32px;
  background-color: #141d38;
  border-style: inset;
}@media (max-width: 480px) {
  .read-more-services-style {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.worker-container {
  display: "flex";
  justify-content: "space-evenly";
  flex-direction: "row";
}

.upper-worker-text {
  font: "Montserrat";
  font-weight: 700;
  font-size: 14px;
  color: #7142b1;
}


.middleBot-worker-text {
  font: "Montserrat";
  font-weight: normal;
  font-size: 15px;
  color: #6d6d6d;
  letter-spacing: -0.38px;
}

.bot-worker-text {
  font: italic normal bold 15px/30px Montserrat;
  font-weight: 800;
  font-size: 15px;
  letter-spacing: -0.38px;
  color: #1b1d21;
  margin-bottom: 40px;
}

.video-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.video-button > p {
  margin-left: 6%;
  color: #1b1d21;
  font-weight: bold;
  cursor: pointer;
}

.services {
  background-image: linear-gradient( to bottom right,#232238, #252150 );
}


.main-footer{
  background-color: #201D3C;
}


.main-footer-style{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    color:white;
    /* padding: 7vw; */
    font: 'Montserrat';
    
  }
  
.footer-title-style{
    font-size: 16px;
  }
.footer-list-style{
    font-size: 15px;
    color: #8E88B9;
}
.footer-list-style>p{cursor: pointer;}

@media (max-width: 768px) {
  .main-footer-style{
    display: none;
  }
  .main-footer{
    display: flex ;
    justify-content: center;
    align-items: center;
  }
}
.services {
    background-image: linear-gradient( to bottom right,#232238, #252150 );
  }
@media (max-width: 425px) {
    #cards-circuit {
     display: grid!important;
    }
  } 
  

.items-parent-style{
    border:1px solid #46416B;
    background-color:#262151;
    margin:1%;
    position: relative;
  }
  
  .items-parent-style>div{margin: 7%;}
  
  .items-color-unSelected {
    color: white;
    font: normal normal 600 14px/30px Montserrat;
  }
  .items-color-selected {
    color: #44baff;
    background-color: #201b3e;
    font: normal normal 600 14px/30px Montserrat;
  }
  .items-description {
    text-align: left;
    font: normal normal normal 15px/30px Montserrat;
    letter-spacing: -0.38px;
    color: #a5a1c3;
  }
  .items-title {
    text-align: left;
    font: normal normal bold 23px/36px Montserrat;
    color: #ffffff;
  }
  .section-title{
    color: #8E88B9;
    font: normal normal 600 14px/30px Montserrat;
    text-align: center;
    padding-top: 50px;
  }
  .section-subtitle{
    color: #FFFFFF;
    font:normal normal bold 37px/45px Montserrat;
    text-align: center;
  }
  .corner-style{
      margin: 0 !important;
      position: absolute;
      background: transparent linear-gradient(180deg, #23B3DD 0%, #42619C 100%) 0% 0% no-repeat padding-box;
      padding: 20px;
      right: 0px;
      border-radius: 0% 0% 0% 95%;
      color: #FFFFFF;
      font: normal normal 300 37px/45px Montserrat;
  }



.company-style {
  background-color: #f8f8f8;
  justify-content: "space-around";
  display: "flex";
  padding: 70px;
}

.companies-section {
  justify-content: space-between;
  display: flex !important;
  background-color: #f8f8f8;
  padding: 70px;
}

@media (max-width: 768px) {
  .companies-section {
    overflow-x: auto;
  }
}

